<template>
    <div>
        <top-nav></top-nav>
        <uc-header-guide></uc-header-guide>
        <bread-crumb></bread-crumb>
        <div class="uc-bd">
            <div class="container">
                <el-menu
                    active-text-color="#fff"
                    :default-active="activeIndex"
                    :router="true"
                    menu-trigger="hover"
                    :collapse-transition="false"
                    :default-openeds="['1','2','3','4','5']"
                    class="uc-nav-menu"
                >
                    <NavMenu :navMenus="menuData"></NavMenu>
                </el-menu>
                <router-view class="uc-contarea"></router-view>
            </div>
        </div>
        <global-footer></global-footer>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import TopNav from "@/components/common/TopNav.vue";
import UcHeaderGuide from "@/components/common/UcHeaderGuide.vue";
import NavMenu from "@/components/navmenu/NavMenu.vue";
import GlobalFooter from "@/components/common/GlobalFooter.vue";
import BreadCrumb from "@/components/common/BreadCrumb.vue";

export default {
    name: "MainIndex",
    components: {
        NavMenu: NavMenu, // 菜单
        TopNav, // 头部吊顶
        UcHeaderGuide, //头部指南
        GlobalFooter, // 公共底部
        BreadCrumb,//当前页面位置
    },

    data() {
        return {
            activeIndex: "0", //此处为munuData里面的id
            msgNum: 0,//消息数
        };
    },

    created() {
        this.setActiveNavIndex(this.menuData);
        this.getIconData(this.menuData);
    },

    methods: {
        setActiveNavIndex(menuDatas) {
            var localhostUriString = window.location.href;
            for (var item in menuDatas) {
                if (menuDatas[item].hasOwnProperty("childs")) {
                    this.setActiveNavIndex(menuDatas[item].childs);
                } else {
                    if (localhostUriString.indexOf(menuDatas[item].entity.name) != -1) {
                        this.activeIndex = menuDatas[item].entity.id;
                    }
                }
            }
        },

        getIconData(menuDatas) {
            var localhostUriString = window.location.href;
            for (var item in menuDatas) {
                if (menuDatas[item].entity.id == 11) {
                    if (this.userInfo) {
                        menuDatas[item].entity.msgNum = this.userInfo.info.new_message_count;
                    }
                }
                if (menuDatas[item].entity.id == 4) {
                    if (this.userInfo) {
                        // menuDatas[item].entity.msgNum = this.userInfo.statistic._matters.num;
                    }
                }
                if (menuDatas[item].entity.id == 7) {
                    // menuDatas[item].entity.msgNum = this.userInfo.statistic._evaluate;
                }
            }
        },
    },

    computed: {
        ...mapState(["userInfo"]),//获取用户信息

        //左侧菜单数据
        menuData() {
            return [
                {
                    entity: {
                        id: "0",
                        name: "/user/index",
                        icon: "el-icon-mouse", // 导航前面显示的图标， 填写图标名称即可
                        alias: "控制台首页"
                    }
                },
                {
                    entity: {
                        id: "1",
                        name: "/user/info",
                        icon: "",
                        alias: "信息中心",
                        // authentication: false, // 是否认证
                        // attestation: 4
                    },
                    childs: [
                        {
                            entity: {
                                id: "1-1",
                                name: "/user/info",
                                icon: "el-icon-user",
                                alias: "用户信息"
                            }
                        },
                        {
                            entity: {
                                id: "1-3",
                                name: this.msgNum > 0 ? "/user/message?id=3" : "/user/message?id=2",
                                icon: "el-icon-postcard",
                                alias: "消息中心",
                                msgNum: this.userInfo.info.new_message_count
                            }
                        },
                        {
                            entity: {
                                id: "1-4",
                                name: "/user/article/1",
                                icon: "el-icon-news",
                                alias: "资源分享"
                            }
                        }
                    ]
                },
                {
                    entity: {
                        id: "2",
                        name: "/user/task/list",
                        icon: "",
                        alias: "项目管理",
                        // authentication: false, // 是否认证
                        // attestation: 4
                    },
                    childs: [
                        {
                            entity: {
                                id: "2-1",
                                name: "/user/task/list?status=0",
                                icon: "el-icon-files",
                                alias: "我的项目"
                            }
                        },
                        // {
                        //     entity: {
                        //         id: "2-1",
                        //         name: "/user/task/list?status=0",
                        //         icon: "el-icon-files",
                        //         alias: "所有项目"
                        //     }
                        // },
                        // {
                        //     entity: {
                        //         id: "2-2",
                        //         name: "/user/task/list?status=2",
                        //         icon: "el-icon-coordinate",
                        //         alias: "审核中项目",
                        //         isDisabled: this.userInfo.info.user_type == 1  // 显示判断条件，极客没有审核中的项目 
                        //     }
                        // },
                        // {
                        //     entity: {
                        //         id: "2-3",
                        //         name: "/user/task/list",
                        //         icon: "el-icon-c-scale-to-original", // 左侧图标
                        //         iconRight: 'el-icon-arrow-right', // 右侧图标
                        //         alias: "进行中项目",
                        //         levelClass: 'has-third-level',
                        //     },
                        //     childs: [ // 第三级菜单，当鼠标滑过时，从右侧显示出第三级菜单 进行中项目
                        //         {
                        //             entity: {
                        //                 levelClass: 'third-level',
                        //                 id: "2-3-1",
                        //                 name: "/user/task/list?status=3",
                        //                 icon: "el-icon-files",
                        //                 alias: this.userInfo.info.user_type == 1 ? "我报名的项目" : "报名中的项目",
                        //             }
                        //         },
                        //         {
                        //             entity: {
                        //                 levelClass: 'third-level',
                        //                 id: "2-3-2",
                        //                 name: "/user/task/list?status=4",
                        //                 icon: "el-icon-files",
                        //                 alias: this.userInfo.info.user_type == 1 ? "我中标的项目" : "评选中项目"
                        //             }
                        //         },
                        //         {
                        //             entity: {
                        //                 levelClass: 'third-level',
                        //                 id: "2-3-3",
                        //                 name: "/user/task/list?status=5",
                        //                 icon: "el-icon-files",
                        //                 alias: "待签署项目"
                        //             }
                        //         },
                        //         {
                        //             entity: {
                        //                 levelClass: 'third-level',
                        //                 id: "2-3-4",
                        //                 name: "/user/task/list?status=6",
                        //                 icon: "el-icon-files",
                        //                 alias: this.userInfo.info.user_type == 1 ? "待托管保证金项目" : "待托管酬金项目"
                        //             }
                        //         },
                        //         {
                        //             entity: {
                        //                 levelClass: 'third-level',
                        //                 id: "2-3-5",
                        //                 name: "/user/task/list?status=7",
                        //                 icon: "el-icon-files",
                        //                 alias: "工作中项目"
                        //             }
                        //         },
                        //     ]
                        // },
                        // {
                        //     entity: {
                        //         id: "2-6",
                        //         name: "/user/task/list?status=9",
                        //         icon: "el-icon-takeaway-box",
                        //         alias: "调解中项目"
                        //     }
                        // },
                        // {
                        //     entity: {
                        //         id: "2-4",
                        //         name: "/user/task/list?status=8",
                        //         icon: "el-icon-document-checked",
                        //         alias: "已完成项目"
                        //     }
                        // },
                        // {
                        //     entity: {
                        //         id: "2-5",
                        //         name: "/user/task/list?status=10",
                        //         icon: "el-icon-document-delete",
                        //         alias: "已结束项目"
                        //     }
                        // }
                    ]
                },
                {
                    entity: {
                        id: "3",
                        name: "/user/task/list?status=0",
                        icon: "",
                        alias: "个人中心",
                        // authentication: false, // 是否认证
                        // attestation: 4
                    },
                    childs: [
                        {
                            entity: {
                                id: "3-1",
                                name: "/user/collect",
                                icon: "el-icon-star-off",
                                alias: "关注与收藏"
                            }
                        },
                        {
                            entity: {
                                id: "3-2",
                                name: "/user/appraise",
                                icon: "el-icon-thumb",
                                alias: "评价管理",
                            }
                        },
                        {
                            entity: {
                                id: "3-3",
                                name: "/user/wallet/list",
                                icon: "el-icon-bank-card",
                                alias: "我的钱包"
                            }
                        },
                        {
                            entity: {
                                id: "3-4",
                                name: "/user/setting/",
                                icon: "el-icon-orange",
                                alias: "账户安全"
                            }
                        },
                        // {
                        //     entity: {
                        //         id: "3-5",
                        //         name: "/user/setting/privacy",
                        //         icon: "el-icon-lock",
                        //         alias: "隐私设置"
                        //     }
                        // }
                    ]
                }
            ];
        }
    },

    watch: {
        $route: {
            handler: function (val, oldVal) {
                var menuDatas = this.menuData;
                this.setActiveNavIndex(menuDatas);
            },
            deep: true
        },
    }
};
</script>

<style lang="less" >
.el-submenu .el-submenu__title {
    text-align: center;
}
</style>