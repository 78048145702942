<template>
    <div class="navMenu">
        <template v-for="(navMenu,menuIndex) in navMenus">
            <!-- 最后一级菜单 -->
            <el-menu-item
                v-if="!navMenu.childs&&navMenu.entity && !navMenu.entity.isDisabled"
                :key="navMenu.entity.id"
                :data="navMenu"
                :index="navMenu.entity.id"
                :class="navMenu.entity.levelClass"
                @click="routerTo(menuIndex)"
            >
                <i :class="navMenu.entity.icon"></i>
                <span :class="navMenu.entity.levelClass">{{navMenu.entity.alias}}</span>
                <el-badge
                    slot="title"
                    :value="navMenu.entity.msgNum"
                    v-if="navMenu.entity.msgNum>0"
                    :max="100"
                    class="item menu_badge"
                ></el-badge>
                <i class="it-icon-right" :class="navMenu.entity.iconRight"></i>
            </el-menu-item>

            <!-- 此菜单下还有子菜单 -->
            <el-submenu
                v-if="navMenu.childs&&navMenu.entity && !navMenu.entity.isDisabled"
                :class="navMenu.entity.levelClass"
                :key="navMenu.entity.id"
                :data="navMenu"
                :index="navMenu.entity.id"
                :disabled="navMenu.entity.isDisabled"
                @click="routerTo(menuIndex)"
            >
                <template slot="title">
                    <i :class="navMenu.entity.icon"></i>
                    <el-badge
                        :value="navMenu.entity.msgNum"
                        :max="100"
                        class="item"
                    >{{navMenu.entity.alias}}</el-badge>
                    <i class="it-icon-right" :class="navMenu.entity.iconRight"></i>
                </template>
                <!-- 递归 -->
                <NavMenu :navMenus="navMenu.childs"></NavMenu>
            </el-submenu>
        </template>
    </div>
</template>

<script>
export default {
    name: "NavMenu",
    props: ["navMenus"],
    data() {
        return {
            openeds: ["0"]
        };
    },
    methods: {
        routerTo(index) {
            this.$router.push(this.navMenus[index].entity.name);
        }
    }
};
</script>
<style lang="less" scoped>
.el-menu-item {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    color: #333;
    font-weight: 500;
    &:hover {
        background: #e6e6e6;
    }
}
.el-menu-item {
    padding: 0 25px;
    &:before {
        display: none;
    }
    &.is-active {
        background: #587ddc;
        font-size: 14px;
        &:before {
            display: none;
        }
    }
}
/deep/.el-submenu {
    & + .el-submenu {
        margin-top: 6px;
    }
    &.is-opened {
        .el-submenu__title {
            color: #999999 !important;
        }
    }
    &.is-active {
        .el-submenu__title {
            background: none;
            color: #333;
            font-size: 14px;
        }
    }
    .el-menu-item {
        font-size: 14px;
        color: #333;
        height: 44px;
        line-height: 44px;
    }
}
/deep/.el-submenu__title {
    display: none; /*  此处隐藏一级菜单，如果要显示，注释此行  */
    height: 30px !important;
    line-height: 30px !important;
    text-align: left;
    .el-submenu__icon-arrow {
        display: none;
    }
}
.authentication {
    color: red;
}
/deep/.el-badge__content {
    height: 18px;
    padding: 0 3px;
    line-height: 16px;
    top: 25px;
    right: -5px;
}
.menu_badge {
    line-height: 0px;
    .el-badge__content {
        line-height: 15px;
    }
}
/deep/.el-menu::after {
    content: " ";
    position: relative;
    z-index: 999;
    display: block;
    width: 160px;
    height: 1px;
    margin: 0 auto;
    margin-top: 5px;
    border-bottom: 1px dashed #c3c3c3;
}
.uc-nav-menu > .navMenu > .el-submenu:last-of-type > .el-menu--inline::after {
    display: none;
}
/* 三级菜单 */
/deep/.has-third-level {
    height: 44px;
    line-height: 44px;
    color: white !important;
    > .el-submenu__title {
        display: block;
    }
    .el-menu-item {
        padding-left: 30px !important;
        height: 40px;
        line-height: 40px;
    }
    .el-menu {
        /* 最后一级菜单 */
        position: absolute;
        z-index: 999;
        right: -100%;
        top: -1px;
        width: 200px;
        height: 220px !important;
        background: #fafafa;
        color: white;
        border: 1px solid #eee;
        // display: none;
        box-shadow: 2px 0px 10px rgba(200, 200, 200, 0.2);
        &::after {
            display: none;
        }
    }
    &:hover {
        background: #e6e6e6 !important;
        .el-menu {
            /* 最后一级菜单显示 */
            display: block !important;
        }
    }
    &.el-submenu .el-menu-item,
    &.el-submenu .el-submenu__title {
        height: 44px !important;
        line-height: 44px !important;
        color: #333 !important;
        cursor: pointer;
        opacity: 1;
        &:hover {
            //  background: #e6e6e6!important;
        }
    }
    .it-icon-right {
        display: inline-block;
        width: 14px;
        height: 14px;
        position: absolute;
        right: 20px;
        bottom: 0;
        top: 0;
        margin: auto;
    }
}
.has-third-level .is-active.third-level {
    color: white !important;
}
.navMenu .has-third-level.is-active.has-third-level,
/deep/.navMenu .has-third-level.is-active.has-third-level .el-submenu__title,
.has-third-level.is-active.has-third-level .el-submenu__title i {
    color: white !important;
    background: #587ddc;
}
</style>
